'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var ReactDom = require("react-dom");
var MultiselectDropdown = require("../components/MultiselectDropdown.bs.js");

function str(prim) {
  return prim;
}

function label(t) {
  var tmp;
  switch (t.TAG | 0) {
    case /* City */0 :
        tmp = "City";
        break;
    case /* State */1 :
        tmp = "State";
        break;
    case /* Country */2 :
        tmp = "Country";
        break;
    case /* Search */3 :
        tmp = "Search";
        break;
    
  }
  return tmp;
}

function value(t) {
  return t._0;
}

function searchString(t) {
  switch (t.TAG | 0) {
    case /* City */0 :
        return "city " + t._0;
    case /* State */1 :
        return "state" + t._0;
    case /* Country */2 :
        return "country" + t._0;
    case /* Search */3 :
        return t._0;
    
  }
}

function color(t) {
  switch (t.TAG | 0) {
    case /* City */0 :
        return "orange";
    case /* State */1 :
        return "green";
    case /* Country */2 :
        return "blue";
    case /* Search */3 :
        return "purple";
    
  }
}

function makeCity(name) {
  return {
          TAG: /* City */0,
          _0: name
        };
}

function makeState(name) {
  return {
          TAG: /* State */1,
          _0: name
        };
}

function makeCountry(name) {
  return {
          TAG: /* Country */2,
          _0: name
        };
}

function makeSearch(input) {
  return {
          TAG: /* Search */3,
          _0: input
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  makeCity: makeCity,
  makeState: makeState,
  makeCountry: makeCountry,
  makeSearch: makeSearch
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function unselected(searchInput) {
  var citySuggestions = $$Array.map((function (t) {
          return {
                  TAG: /* City */0,
                  _0: t
                };
        }), [
        "Chicago",
        "San Francisco",
        "Los Angeles",
        "Busan",
        "Jerusalem",
        "Bangalore",
        "Cochin",
        "Chennai"
      ]);
  var stateSuggestions = $$Array.map((function (l) {
          return {
                  TAG: /* State */1,
                  _0: l
                };
        }), [
        "Washington",
        "California",
        "Mississippi",
        "Kuala Lumpur",
        "Kerala",
        "Karnataka",
        "Tamil Nadu"
      ]);
  var countrySuggestions = $$Array.map((function (l) {
          return {
                  TAG: /* Country */2,
                  _0: l
                };
        }), [
        "India",
        "USA",
        "Canada",
        "China",
        "Japan",
        "Egypt",
        "Korea"
      ]);
  var searchSuggestion = searchInput.trim() === "" ? [] : [{
        TAG: /* Search */3,
        _0: searchInput
      }];
  return $$Array.append(countrySuggestions, $$Array.append(stateSuggestions, $$Array.append(citySuggestions, searchSuggestion)));
}

function select(setState, selectable) {
  return Curry._1(setState, (function (s) {
                return {
                        searchInput: "",
                        selected: $$Array.append(s.selected, [selectable])
                      };
              }));
}

function deselect(selected, setState, selectable) {
  var newSelected = selected.filter(function (s) {
        return Caml_obj.caml_notequal(s, selectable);
      });
  return Curry._1(setState, (function (param) {
                return {
                        searchInput: "",
                        selected: newSelected
                      };
              }));
}

function updateSearchInput(setState, searchInput) {
  return Curry._1(setState, (function (s) {
                return {
                        searchInput: searchInput,
                        selected: s.selected
                      };
              }));
}

function defaultOptions(param) {
  return [
          {
            TAG: /* Country */2,
            _0: "India"
          },
          {
            TAG: /* State */1,
            _0: "Kerala"
          },
          {
            TAG: /* City */0,
            _0: "Bangalore"
          }
        ];
}

function MultiselectDropdown__Example$DetailedExample(Props) {
  var match = React.useState(function () {
        return {
                searchInput: "",
                selected: []
              };
      });
  var setState = match[1];
  var state = match[0];
  var partial_arg = state.selected;
  return React.createElement("div", {
              className: "mt-4"
            }, React.createElement("h2", {
                  className: "text-xl font-semibold"
                }, "Detailed Example"), React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("label", {
                      className: "block text-xs font-semibold",
                      htmlFor: "MultiselectDropdown__search-input-detailed-example"
                    }, "Filter by:")), React.createElement(Multiselect.make, {
                  onChange: (function (param) {
                      return updateSearchInput(setState, param);
                    }),
                  value: state.searchInput,
                  unselected: unselected(state.searchInput),
                  selected: state.selected,
                  onSelect: (function (param) {
                      return select(setState, param);
                    }),
                  onDeselect: (function (param) {
                      return deselect(partial_arg, setState, param);
                    }),
                  hint: "Select from the above list or search by city name",
                  defaultOptions: [
                    {
                      TAG: /* Country */2,
                      _0: "India"
                    },
                    {
                      TAG: /* State */1,
                      _0: "Kerala"
                    },
                    {
                      TAG: /* City */0,
                      _0: "Bangalore"
                    }
                  ]
                }));
}

var DetailedExample = {
  Selectable: Selectable,
  Multiselect: Multiselect,
  unselected: unselected,
  select: select,
  deselect: deselect,
  updateSearchInput: updateSearchInput,
  defaultOptions: defaultOptions,
  make: MultiselectDropdown__Example$DetailedExample
};

function label$1(_t) {
  
}

function value$1(t) {
  return t._1;
}

var searchString$1 = value$1;

function color$1(_t) {
  return "gray";
}

function makeCountry$1(name, countryCode) {
  return {
          TAG: /* Country */1,
          _0: countryCode,
          _1: name
        };
}

function makeCity$1(name, pincode) {
  return {
          TAG: /* City */0,
          _0: pincode,
          _1: name
        };
}

var Selectable$1 = {
  label: label$1,
  value: value$1,
  searchString: searchString$1,
  color: color$1,
  makeCountry: makeCountry$1,
  makeCity: makeCity$1
};

var Multiselect$1 = MultiselectDropdown.Make(Selectable$1);

var unselected$1 = [
  {
    TAG: /* City */0,
    _0: "",
    _1: "Delhi"
  },
  {
    TAG: /* Country */1,
    _0: "91",
    _1: "India"
  },
  {
    TAG: /* City */0,
    _0: "",
    _1: "Washington D.C"
  },
  {
    TAG: /* Country */1,
    _0: "1",
    _1: "USA"
  }
];

function deselect$1(selected, setState, selectable) {
  var newSelected = selected.filter(function (s) {
        return Caml_obj.caml_notequal(s, selectable);
      });
  return Curry._1(setState, (function (param) {
                return {
                        selected: newSelected,
                        searchString: ""
                      };
              }));
}

function MultiselectDropdown__Example$MinimalExample(Props) {
  var match = React.useState(function () {
        return {
                selected: [],
                searchString: ""
              };
      });
  var setState = match[1];
  var state = match[0];
  var partial_arg = state.selected;
  return React.createElement("div", {
              className: "mt-4"
            }, React.createElement("h2", {
                  className: "text-xl font-semibold"
                }, "Minimal Example"), React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("label", {
                      className: "block text-xs font-semibold",
                      htmlFor: "MultiselectDropdown__search-input"
                    }, "Filter by:")), React.createElement(Multiselect$1.make, {
                  onChange: (function (searchString) {
                      return Curry._1(setState, (function (s) {
                                    return {
                                            selected: s.selected,
                                            searchString: searchString
                                          };
                                  }));
                    }),
                  value: state.searchString,
                  unselected: unselected$1,
                  selected: state.selected,
                  onSelect: (function (selectable) {
                      return Curry._1(setState, (function (s) {
                                    return {
                                            selected: $$Array.append(s.selected, [selectable]),
                                            searchString: ""
                                          };
                                  }));
                    }),
                  onDeselect: (function (param) {
                      return deselect$1(partial_arg, setState, param);
                    }),
                  hint: "Enter your city name"
                }));
}

var MinimalExample = {
  Selectable: Selectable$1,
  Multiselect: Multiselect$1,
  unselected: unselected$1,
  deselect: deselect$1,
  make: MultiselectDropdown__Example$MinimalExample
};

var element = document.querySelector("#DetailedExample");

if (!(element == null)) {
  ReactDom.render(React.createElement(MultiselectDropdown__Example$DetailedExample, {}), element);
}

var element$1 = document.querySelector("#MinimalExample");

if (!(element$1 == null)) {
  ReactDom.render(React.createElement(MultiselectDropdown__Example$MinimalExample, {}), element$1);
}

exports.str = str;
exports.DetailedExample = DetailedExample;
exports.MinimalExample = MinimalExample;
/* Multiselect Not a pure module */
